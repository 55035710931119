const env = process.env.HOST_VARIABLE || 'development';

const devConfig = {
  BASE_URL_AUTH: 'https://apidev.teleperson.com/',
  BASE_URL_AUTH_DONE: 'https://apidev.teleperson.com/',
};

const prodConfig = {
  BASE_URL_AUTH: 'https://api.teleperson.com/',
  BASE_URL_AUTH_DONE: 'https://api.teleperson.com/',
};

const configs = {
  development: devConfig,
  production: prodConfig,
};

export default configs[env];
